import React from 'react'
import ImageGalleryModal from '../Modals/ImageGallery'
import Mason from 'react-stone-mason'
import { DEFAULT_MASON } from '../../ApplicationConstants'
import LazyImage from '../LazyImage'

import './ProjectCard.scss'

const ProjectCard = ({project}) => {
  const [ modalIsOpen, setModalIsOpen ] = React.useState(false);
  const [ activeImage, setActiveImage ] = React.useState(0);
  
  const closeModal = () => { setModalIsOpen(false) }
  const handleViewImage = i => {
    setActiveImage(i)
    setModalIsOpen(true)
  }
  
  const hasMultipleImages = project.images && project.images.length > 1
  
  return <article className='row project-card-container'>
    <div className='small-12 medium-4 large-6'>
      {project.images && project.images[0] && <LazyImage
        className='main-image'
        src={project.images[0].image}
        width={project.images[0].imageDimensions.width}
        height={project.images[0].imageDimensions.height}
        alt={project.name} />}
    </div>
    <div className='small-12 medium-8 large-6'>
      <h2 >
        {project.url && <a href={project.url} dangerouslySetInnerHTML={{__html: project.name}} />}
        {!project.url && <span dangerouslySetInnerHTML={{__html: project.name}} />}
      </h2>
      
      <div className='x-large-padding-bottom' dangerouslySetInnerHTML={{__html: project.description}} />
      
      {project.url && <div className='x-large-padding-bottom large-padding-top'>
        <h4>
          Project page: <a href={project.url}>{project.url}</a>
        </h4>
      </div>}
      
      {hasMultipleImages && <div className='large-padding-top thumb-list'>
        <Mason columns={DEFAULT_MASON}>
          {project.images.map((image, index) => (
            <div key={`thumb${index}`} >
              <div className='thumb-item'>
                <img onClick={() => handleViewImage(index)} key={`i${index}`} src={image.thumbnail} alt={project.name} />
              </div>
            </div>
          ))}
        </Mason>
      </div>}
    </div>
    
    <ImageGalleryModal
      images={project.images}
      title={`images for '${project.name}'`}
      setActiveImage={setActiveImage}
      activeImage={activeImage}
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    />
  </article>
}

export default ProjectCard