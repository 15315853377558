import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PATHS } from '../route-constants'
import IllustrationListRoute from './IllustrationList'
import IllustrationDetailsRoute from './IllustrationDetails'

const IllustrationRoute = (props) => {
    return <Switch>
        <Route path={PATHS.illustrationDetail}><IllustrationDetailsRoute /></Route>
        <Route path={PATHS.illustration}><IllustrationListRoute /></Route>
    </Switch>
}

export default IllustrationRoute
