import { useState, useCallback } from 'react'

export default function useLocalStorage(key, initialValue, lifeSpan) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      const stampedValue = JSON.parse(item)
      const JSONValue = stampedValue
        && stampedValue.expire > Date.now()
        && JSON.parse(stampedValue.JSONValue)

      return JSONValue || initialValue;
    } catch (error) {

      return initialValue;
    }
  })

  const setValue = useCallback(value => {
    try {
      const expire = Date.now() + lifeSpan
      const JSONValue = JSON.stringify(value)
      const stampedValue = { expire, JSONValue }

      setStoredValue(value)
      window.localStorage.setItem(key, JSON.stringify(stampedValue))
    } catch (error) {
      console.log(error);
    }
  }, [key, lifeSpan])

  return [storedValue, setValue];
}