import React, { useState } from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import { PATHS } from '../route-constants'
import BlogAdmin from './routes/Blog'
import IllustrationAdmin from './routes/Illustration'
import ProjectsAdmin from './routes/Projects'
import BlogDetailsAdmin from './routes/BlogDetails'
import ProjectDetailsAdmin from './routes/ProjectDetails'
import IllustrationDetailsAdmin from './routes/IllustrationDetails'
import { SECRET_KEY } from '../../ApplicationConstants'
import { obfuscateString } from '../../util/dataFormat'

export const NEW_ARTICLE_ROUTE = 'new-article'
export const NEW_PROJECT_ROUTE = 'new-project'
export const NEW_ILLUSTRATION_ROUTE = 'new-illustration'

export const ADMIN_PATHS = {
  blog: PATHS.admin + '/blog',
  illustration: PATHS.admin + '/illustration',
  projects: PATHS.admin + '/projects',
  blogDetail: PATHS.admin + '/blog/:itemId',
  illustrationDetail: PATHS.admin + '/illustration/:itemId',
  projectsDetail: PATHS.admin + '/projects/:itemId',
}

const AdminRoute = (props) => {
  const [ adminSecret, setAdminSecret ] = useState(localStorage.getItem(SECRET_KEY))

  
  const handleSetAdminString = () => {
    const newAdminSecret = prompt('Admin Secret:')
    if (newAdminSecret) {
      localStorage.setItem(SECRET_KEY, newAdminSecret)
      setAdminSecret(newAdminSecret)
  
    }
  }
  
  return <div className='container'>
    
    <div className='text-center large-margin-bottom'>
      ADMIN: <NavLink to={ADMIN_PATHS.projects}>Projects</NavLink> -&nbsp;
      <NavLink to={ADMIN_PATHS.illustration}>illustration</NavLink> -&nbsp;
      <NavLink to={ADMIN_PATHS.blog}>blog</NavLink>
    </div>
  
    <div className='text-center large-margin-bottom'>
      secret: [&nbsp;&nbsp;{(adminSecret && obfuscateString(adminSecret)) || <em>empty</em>}&nbsp;&nbsp;]&nbsp;&nbsp;
      <button className='small' onClick={handleSetAdminString}>set</button>
    </div>
    
    <Switch>
      <Route path={ADMIN_PATHS.blogDetail}><BlogDetailsAdmin /></Route>
      <Route path={ADMIN_PATHS.projectsDetail}><ProjectDetailsAdmin /></Route>
      <Route path={ADMIN_PATHS.illustrationDetail}><IllustrationDetailsAdmin /></Route>
      <Route path={ADMIN_PATHS.projects}><ProjectsAdmin /></Route>
      <Route path={ADMIN_PATHS.illustration}><IllustrationAdmin /></Route>
      <Route path={ADMIN_PATHS.blog}><BlogAdmin /></Route>
    </Switch>
  </div>
}

export default AdminRoute
