import React from 'react'

import './Footer.scss'

const Footer = (props) => {

    return <footer className='site-footer'>
        <div className='container no-padding'>
            <div className='row'>
                <div className='small-12 medium-10  offset-small-0 offset-medium-1'>
                    <h2>Greg Archer</h2>
                    <a href='mailto:greg.taff@gmail.com'>greg.taff@gmail.com</a><br/>
                    <a href='https://github.com/nihlton'>GitHub</a><br/>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer
