import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getItemBySlug } from '../../../api'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../route-constants'
import Article from '../../../components/Article'
import { useDocumentTitle } from '../../../util/domUtil'

const BlogDetailsRoute = (props) => {
  const [ article, setArticle ] = useState( false )
  const { itemId } = useParams()

  useDocumentTitle(`Greg / Dev log / ${article?.title || ''}`)


  useEffect(() => {
    itemId && getItemBySlug('blog-entries', itemId).then(res => setArticle(res))
  }, [itemId])

  return <div className='container'>
    {article && <Article article={article} />}

    <div className='row '>
      <div className='hide-for-small medium-3' />
      <div className='small-12 medium-9'>
        <NavLink to={PATHS.blog}>More blog entries</NavLink>
      </div>
    </div>
  </div>
}

export default BlogDetailsRoute
