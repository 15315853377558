import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Modal from 'react-modal'
import { deleteItemBySlug, getImages, getItemBySlug, updateItemBySlug } from '../../../../api'
import { toSlug, toSlugDummy } from '../../../../util/dataFormat'
import { NEW_ILLUSTRATION_ROUTE } from '../../Admin'
import SelectImagesModal from '../../../../components/Modals/SelectImages'
import Illustration from '../../../../components/Illustration'

const defaultIllustration = {
  title: '',
  description: '',
  date: '',
  images: [],
  relatedIllustrations: [],
  slug: '',
}

Modal.setAppElement('#root')

const IllustrationDetailsAdmin = (props) => {
  const [ illustration, setIllustration ] = useState(defaultIllustration)
  const [ illustrationImages, setIllustrationImages ] = useState([])
  const [ modalIsOpen, setIsOpen ] = React.useState(false);
  const { itemId } = useParams()
  
  function openModal () { setIsOpen(true) }
  function closeModal () { setIsOpen(false) }
  
  useEffect(() => {
    itemId !== NEW_ILLUSTRATION_ROUTE && getItemBySlug('illustration', itemId).then(res => setIllustration(res))
  }, [itemId])
  
  const handleDeleteIllustration = (e) => {
    const slug = illustration.slug
    deleteItemBySlug('illustration', slug).then(() => alert('deleted!'))
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleUpdateIllustration = (e) => {
    const { _id, ...illustrationProperties } = illustration
    const slug = illustration.slug || toSlug(illustration.title)
    const date = illustration.date || (new Date()).toISOString()
    updateItemBySlug('illustration', slug, { ...illustrationProperties, slug, date } ).then(() => alert('updated!'))
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleAddImages = e => {
    if (!illustrationImages.length) {
      getImages('illustration', true).then(images => {
        setIllustrationImages(images)
        openModal()
      })
    } else {
      openModal()
    }
    
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleSetImageSelection = (images) => {
    setIllustration({...illustration, images})
  }
  
  const handleBumpDate = e => {
    setIllustration({...illustration, date: (new Date()).toISOString()})
    e.preventDefault()
    e.stopPropagation()
  }
  
  return <div className='container illustration-admin-details-container'>
    <div className='row'>
      <div className='offset-small-0 offset-medium-2 offset-large-2 small-12 medium-10 large-8'>
        <form onSubmit={handleUpdateIllustration}>
          <div>
            <label>
              <span>title</span>
              <input value={illustration.title} onChange={e => setIllustration({...illustration, title: e.target.value})}/>
            </label>
            <label>
              <span>Description</span>
              <textarea value={illustration.description} rows='10' onChange={e => setIllustration({...illustration, description: e.target.value})}/>
            </label>
            <label>
              <span>Date</span>
              <input value={illustration.date} onChange={e => setIllustration({...illustration, date: e.target.value})}/>
              <button onClick={handleBumpDate}>Now</button>
            </label>
            <div>
              Images<br/>
              <div className='medium-padding-top medium-padding-bottom'>
                {(!illustration.images || illustration.images.length === 0) && <em>none</em>}
                {(illustration.images || []).map((image, i) => <div key={`image-${i}`}>{image.image}</div>)}
              </div>
              <button className='secondary' onClick={handleAddImages}>select image</button>
            </div>
            <p>slug: { illustration.slug || toSlugDummy(illustration.title) } </p>
            <div className='row no-gutter xx-large-padding-top'>
              <div className='small-6 text-left no-cell-padding'>
                {itemId !== NEW_ILLUSTRATION_ROUTE && <button className='secondary red' onClick={handleDeleteIllustration}>Delete</button>}
              </div>
              <div className='small-6 text-right no-cell-padding'>
                <button>{itemId === NEW_ILLUSTRATION_ROUTE ? 'Save' : 'Update'}</button>&nbsp;
              </div>
            </div>
          </div>
        </form>
        <hr/>
        <strong>preview</strong>
        <Illustration illustration={{...illustration, date: (new Date()).toISOString() }} />
        {/*<Project project={{...project, date: (new Date()).toISOString() }} />*/}
      </div>
    </div>
    
    <SelectImagesModal
      selectedImages={illustration.images || []}
      imageOptions={illustrationImages}
      setSelectedImages={handleSetImageSelection}
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    />
  
  
  </div>
}

export default IllustrationDetailsAdmin