import React from 'react'
import Modal from 'react-modal'
import Mason from 'react-stone-mason'
import { DEFAULT_MASON } from '../../ApplicationConstants'
import { altFromImageUrl } from '../../util/dataFormat'

import './ImageGallery.scss'

const ImageGalleryModal = (props) => {
  const {
    title,
    images = [],
    setActiveImage,
    activeImage,
    modalIsOpen,
    closeModal
  } = props
  
  const currentImage = images && images[activeImage || 0]
  
  return <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    contentLabel="Select Images"
  >
    <div className='image-gallery-modal'>
      <h2>{title || 'Images'}</h2>
      <button className='modal-close' onClick={closeModal}>close</button>
      <div className='large-padding-top x-large-padding-bottom'>
        {currentImage && <img src={`${currentImage.image}`} alt={altFromImageUrl(currentImage.image)} />}
      </div>
      <div className='thumb-list'>
        <Mason columns={DEFAULT_MASON}>
        {images.map((image, i) => {
          return <div key={`thumb${i}`} >
            <div className={`thumb-item ${i === activeImage ? 'selected' : ''}`}>
              <img
                onClick={() => setActiveImage(i)}
                
                src={`${image.thumbnail}`}
                alt={altFromImageUrl(images[0].image)}/>
            </div>
          </div>
        })}
        </Mason>
      </div>
    </div>
  </Modal>
}
export default ImageGalleryModal