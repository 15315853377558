import React from 'react'
import ImageGalleryModal from '../Modals/ImageGallery'
import LazyImage from '../LazyImage'
import Mason from 'react-stone-mason'
import { DEFAULT_MASON } from '../../ApplicationConstants'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../routes/route-constants'

import './Article.scss'

const Article = ({article}) => {
  const [ modalIsOpen, setModalIsOpen ] = React.useState(false);
  const [ activeImage, setActiveImage ] = React.useState(0);
  
  const closeModal = () => { setModalIsOpen(false) }
  const handleViewImage = i => {
    setActiveImage(i)
    setModalIsOpen(true)
  }
  
  const timeStyleOptions = {
    hour: 'numeric',
    minute: 'numeric',
  }
  const dateStyleOptions = {
    dateStyle: 'long',
  }
  
  const postDate = new Date(article.date)
  const dateDisplay = new Intl.DateTimeFormat('en-US', dateStyleOptions).format(postDate)
  const timeDisplay = new Intl.DateTimeFormat('en-US', timeStyleOptions).format(postDate)
  const hasMultipleImages = article.images && article.images.length > 1
  
  return <article className='row blog-article'>
    <div className='hide-for-small medium-3' />
    <div className='small-12 medium-9'>
      <h2 dangerouslySetInnerHTML={{__html: article.title}} />
    </div>
    <div className='small-12 medium-3 date-display'>
      <NavLink to={PATHS.blogDetail.replace(':itemId', article.slug)}>
        <span className='hide-for-small'>{dateDisplay}<br/>{timeDisplay}</span>
        <span className='show-for-small'>{dateDisplay}, {timeDisplay}</span>
      </NavLink>
    </div>
    <div className='small-12 medium-9'  >
      {article.images && article.images[0] && <LazyImage
        className='main-image'
        src={article.images[0].image}
        alt={article.title}
        width={article.images[0].imageDimensions.width}
        height={article.images[0].imageDimensions.height}
      />}
      <div className='x-large-padding-bottom' dangerouslySetInnerHTML={{__html: article.body}} />
  
      {hasMultipleImages && <div className='large-padding-top thumb-list'>
        <Mason columns={DEFAULT_MASON}>
          {article.images.map((image, index) => (
            <div key={`thumb${index}`} >
              <div className='thumb-item'>
                <img onClick={() => handleViewImage(index)} key={`i${index}`} src={image.thumbnail} alt={article.title} />
              </div>
            </div>
          ))}
        </Mason>
      </div>}
    </div>
  
    <ImageGalleryModal
      images={article.images}
      title={article.title}
      setActiveImage={setActiveImage}
      activeImage={activeImage}
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    />
  </article>
}

export default Article