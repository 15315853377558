import React, { useEffect } from 'react'
import { getItems } from '../../api'
import {NavLink} from "react-router-dom";
import {PATHS} from "../route-constants";
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../util/domUtil'
import useLocalStorage from '../../util/useLocalStorage'
import { siloEffect } from '../../util'

import './Home.scss'

const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24



const HomeRoute = (props) => {
  const [illustrations, setIllustrations] = useLocalStorage('ill', false, ONE_DAY)
  const [codeProjects, setCodeProjects] = useLocalStorage('code', false, ONE_DAY)
  const [blogEntries, setBlogEntries] = useLocalStorage('blog', false, ONE_HOUR)

  useDocumentTitle('Greg Archer')

  useEffect(...siloEffect(illustrations, 'illustration', getItems, setIllustrations))
  useEffect(...siloEffect(codeProjects, 'code-projects', getItems, setCodeProjects))
  useEffect(...siloEffect(blogEntries, 'blog-entries', getItems, setBlogEntries))

  const handleScroll = (e) => {
    const container = e.target;
    container.style.setProperty("--x", container.scrollLeft / container.offsetWidth);
    container.style.setProperty("--v", container.scrollLeft / container.offsetWidth - .7);
  }

  const containerStyle = {} // background ? { backgroundImage: `url('${background.image}')`} : {}

  return <div
    className='home-container'
    style={containerStyle}
  >
    <div className='container'>
      <div className='silo-list' onScroll={handleScroll}>
        {codeProjects && <div className='silo-container'>
          <section className='silo projects'>
            <h1><NavLink to={PATHS.codeProjects} data-text='Projects'>Projects</NavLink></h1>
            <ul>
              {codeProjects.slice(0, 7).map((project, i) => <li key={`project-${project.slug}`}>
                <NavLink to={PATHS.codeProjectDetail.replace(':itemId', project.slug)} >{project.name}</NavLink>
              </li>)}
              {codeProjects.slice(7, 10).map((project, i) => <li key={`project-${project.slug}`} className='hide-for-small'>
                <NavLink to={PATHS.codeProjectDetail.replace(':itemId', project.slug)} >{project.name}</NavLink>
              </li>)}
              <li><NavLink to={PATHS.codeProjects}>more ...</NavLink></li>

            </ul>
          </section>
        </div>}

        {illustrations && <div className='silo-container'>
          <section className='silo illustration'>
            <h1><NavLink to={PATHS.illustration} data-text='Illustration'>Illustration</NavLink></h1>
            <ul>
              {illustrations.slice(0, 7).map((illustration, i) => <li key={`ill-${illustration.slug}`}>
                <NavLink to={PATHS.illustrationDetail.replace(':itemId', illustration.slug)} >{illustration.title}</NavLink>
              </li>)}
              {illustrations.slice(7, 10).map((illustration, i) => <li key={`ill-${illustration.slug}`} className='hide-for-small'>
                <NavLink to={PATHS.illustrationDetail.replace(':itemId', illustration.slug)} >{illustration.title}</NavLink>
              </li>)}
              <li><NavLink to={PATHS.illustration}>more ...</NavLink></li>
            </ul>
          </section>
        </div>}

        {blogEntries && <div className='silo-container'>
          <section className='silo blog'>
            <h1><NavLink to={PATHS.blog} data-text='Blog'>Blog</NavLink></h1>
            <ul>
              {blogEntries.slice(0, 7).map((post, i) => <li key={`post-${post.slug}`}>
                <NavLink to={PATHS.blogDetail.replace(':itemId', post.slug)} ><span dangerouslySetInnerHTML={{__html: post.title}} /></NavLink>
              </li>)}
              {blogEntries.slice(7, 10).map((post, i) => <li key={`post-${post.slug}`} className='hide-for-small'>
                <NavLink to={PATHS.blogDetail.replace(':itemId', post.slug)} ><span dangerouslySetInnerHTML={{__html: post.title}} /></NavLink>
              </li>)}
              <li><NavLink to={PATHS.blog}>more ...</NavLink></li>
            </ul>
          </section>
        </div>}

      </div>
    </div>

    <Footer />
  </div>
}

export default HomeRoute
