import { toParamString } from '../util/dataFormat'
import { SECRET_KEY } from '../ApplicationConstants'

export const logErrors = (err) => console.log(err)

const isJSON = (res) => {
    const contentType = res.headers.get('content-type')
    return contentType && contentType.indexOf('application/json') !== -1
}

export const resHandler = res => {
    if (res && res.ok) {
        return res.text().then(resText => {
            try {
                return JSON.parse(resText);
            } catch (e) {
                return resText;
            }
        })
    } else {
        if (isJSON(res)) {
            return res.json().then(error => {
                logErrors(error)
                throw error
            })
        } else {
            logErrors(res.statusText)
            throw res
        }
    }
}

export const getItems = function (type, skip = 0, limit = 10, sort = 'date') {
    const searchParams = { skip, limit, sort }
    const config = { method: 'GET', credentials: 'same-origin' }
    return fetch(`${process.env.REACT_APP_API_URL}api/${type}?${toParamString(searchParams)}`, config).then(resHandler)
}

export const getItemBySlug = function (type, slug) {
    return fetch(`${process.env.REACT_APP_API_URL}api/${type}/${slug}`).then(resHandler)
}

export const getImages = function (type, withDimensions) {
    const params = withDimensions ? { type, 'include-dimensions=true': true } : { type }
    const paramString = toParamString(params)
    return fetch(`/cgi-bin/images.py?${paramString}`).then(resHandler).then(res => res[type])
}

export const updateItemBySlug = function (type, slug, item) {
    const secret = localStorage.getItem(SECRET_KEY)
    const apiUrl = `${process.env.REACT_APP_API_URL}api/${type}/${slug}`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({secret, item}),
    }
    return fetch(apiUrl, options).then(resHandler)
}

export const deleteItemBySlug = function (type, slug) {
    const secret = localStorage.getItem(SECRET_KEY)
    const apiUrl = `${process.env.REACT_APP_API_URL}api/${type}/${slug}?secret=${secret}`
    const options = {
        method: 'DELETE',
    }
    return fetch(apiUrl, options).then(resHandler)
}