import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PATHS } from '../route-constants'
import ProjectsListRoute from './ProjectList'
import ProjectsDetailsRoute from './ProjectDetails'

const ProjectsRoute = (props) => {
    return <Switch>
        <Route path={PATHS.codeProjectDetail}><ProjectsDetailsRoute /></Route>
        <Route path={PATHS.codeProjects}><ProjectsListRoute /></Route>
    </Switch>
}

export default ProjectsRoute
