import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getItemBySlug } from '../../../api'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../route-constants'
import Illustration from '../../../components/Illustration'
import { useDocumentTitle } from '../../../util/domUtil'

const IllustrationDetailsRoute = (props) => {
  const [ illustration, setIllustration ] = useState( false )
  const { itemId } = useParams()

  useDocumentTitle(`Greg / Illustration / ${illustration?.title || ''}`)

  useEffect(() => {
    itemId && getItemBySlug('illustration', itemId).then(res => setIllustration(res))
  }, [itemId])

  return <div className='container'>
    {illustration && <Illustration illustration={illustration} />}

    <NavLink to={PATHS.illustration}>More Illustrations</NavLink>
  </div>
}

export default IllustrationDetailsRoute
