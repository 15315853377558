import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Modal from 'react-modal'
import { deleteItemBySlug, getImages, getItemBySlug, updateItemBySlug } from '../../../../api'
import Article from '../../../../components/Article'
import { toSlug, toSlugDummy } from '../../../../util/dataFormat'
import { NEW_ARTICLE_ROUTE } from '../../Admin'
import SelectImagesModal from '../../../../components/Modals/SelectImages'

import './BlogDetails.scss'


const defaultArticle = {
  title: '',
  body: '',
  date: '',
  images: [],
  slug: '',
}

Modal.setAppElement('#root')

const BlogDetailsAdmin = (props) => {
  const [ article, setArticle ] = useState(defaultArticle)
  const [ blogImages, setBlogImages] = useState([])
  const [ modalIsOpen, setIsOpen ] = React.useState(false);
  const { itemId } = useParams()
  
  function openModal () { setIsOpen(true) }
  function closeModal () { setIsOpen(false) }
  
  useEffect(() => {
    itemId !== NEW_ARTICLE_ROUTE && getItemBySlug('blog-entries', itemId).then(res => setArticle(res))
  }, [itemId])
  
  const handleDeleteArticle = (e) => {
    const slug = article.slug
    deleteItemBySlug('blog-entries', slug).then(() => alert('deleted!'))
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleUpdateArticle = (e) => {
    const { _id, ...articleProperties } = article
    const slug = article.slug || toSlug(article.title)
    const date = article.date || (new Date()).toISOString()
    updateItemBySlug('blog-entries', slug, { ...articleProperties, slug, date } ).then(() => alert('updated!'))
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleAddImages = e => {
    if (!blogImages.length) {
      getImages('blog-entries', true).then(images => {
        setBlogImages(images)
        openModal()
      })
    } else {
      openModal()
    }

    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleSetImageSelection = (images) => {
    setArticle({...article, images})
  }
  
  return <div className='container blog-admin-details-container'>
    <div className='row'>
      <div className='offset-small-0 offset-medium-2 offset-large-2 small-12 medium-10 large-8'>
        <form onSubmit={handleUpdateArticle}>
          <div>
            <label>
              <span>Title</span>
              <input value={article.title} onChange={(e) => setArticle({...article, title: e.target.value})}/>
            </label>
            <label>
              <span>Body</span>
              <textarea value={article.body} rows='10' onChange={(e) => setArticle({...article, body: e.target.value})}/>
            </label>
            <div>
              Images<br/>
              <div className='medium-padding-top medium-padding-bottom'>
                {(!article.images || article.images.length === 0) && <em>none</em>}
                {(article.images || []).map((image, i) => <div key={`image-${i}`}>{image.image}</div>)}
              </div>
              <button className='secondary' onClick={handleAddImages}>select image</button>
            </div>
            <p>slug: { article.slug || toSlugDummy(article.title) } </p>
            <div className='row no-gutter xx-large-padding-top'>
              <div className='small-6 text-left no-cell-padding'>
                {itemId !== NEW_ARTICLE_ROUTE && <button className='secondary red' onClick={handleDeleteArticle}>Delete</button>}
              </div>
              <div className='small-6 text-right no-cell-padding'>
                <button>{itemId === NEW_ARTICLE_ROUTE ? 'Save' : 'Update'}</button>&nbsp;
              </div>
            </div>
          </div>
        </form>
        <hr/>
        <strong>preview</strong>
        <Article article={{...article, date: (new Date()).toISOString() }} />
      </div>
    </div>
  
    <SelectImagesModal
      selectedImages={article.images || []}
      imageOptions={blogImages}
      setSelectedImages={handleSetImageSelection}
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    />
    
    
  </div>
}

export default BlogDetailsAdmin