import React, { useEffect, useState } from 'react'
import { getItems } from '../../../api'
import ProjectCard from '../../../components/Project/ProjectCard'

import './ProjectList.scss'
import { useDocumentTitle } from '../../../util/domUtil'

const ProjectsListRoute = (props) => {
  const [projects, setProjects] = useState([])
  
  useDocumentTitle('Greg / Projects')
  
  
  useEffect(() => {
    getItems('code-projects', 0, 0, 'date').then(res => res && setProjects(res))
  }, [])
  
  return <div className='project-list-container'>
    <div className='container'>
      <header className='row child-page-heading' style={{ '--color': 'var(--red)'}}>
        <div className='hide-for-small medium-4 large-6' />
        <div className='small-12 medium-8 large-6'>
          <h1 className='' >Projects</h1>
        </div>
      </header>
      
      {projects.map((project, i) => <div key={`p${i}`}  className='project-item'>
        <ProjectCard project={project} />
        <hr />
      </div>)}
      
    </div>
  </div>
}

export default ProjectsListRoute
