import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PATHS } from '../route-constants'

import BlogListRoute from './BlogList'
import BlogDetailsRoute from './BlogDetails'

const BlogsRoute = (props) => {
    return <Switch>
        <Route path={PATHS.blogDetail}><BlogDetailsRoute /></Route>
        <Route path={PATHS.blog}><BlogListRoute /></Route>
    </Switch>
}

export default BlogsRoute
