import React, { useEffect, useRef, useState } from 'react'

import './LazyImage.scss'

const LazyImage = (props) => {
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ isVisible, setIsVisible ] = useState(false)
  const { src, alt, width, height, className, onLoad } = props
  const placeHolderStyle = {paddingBottom: `${(height / width) * 100}%`}
  const imageRef = useRef()
  
  useEffect(() => {
    const handleVisibility = (entries) => {
      if (entries[0].intersectionRatio > 0 || entries[0].isIntersecting) {
        setIsVisible(true)
        visibilityObserver.disconnect()
      }
    }
    const visibilityObserver = new IntersectionObserver(handleVisibility)
    visibilityObserver.observe(imageRef.current)
    
    return () => visibilityObserver.disconnect()
  }, [ imageRef, setIsVisible ])
  
  const handleLoad = () => {
    onLoad && onLoad()
    setIsLoaded(true)
  }
  
  return <div className={`lazy-image ${isLoaded ? '' : 'loading'} ${className || ''}` } ref={imageRef}>
    {isVisible && <img onLoad={handleLoad} onError={handleLoad} src={src} alt={alt}/>}
    <div className='flow-holder' style={placeHolderStyle}/>
  </div>
}

export default LazyImage