import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../routes/route-constants'
import LazyImage from '../LazyImage'

import './IllustrationCard.scss'

const IllustrationCard = ({illustration}) => {
  const [ imageLoaded, setImageLoaded ] = useState(false)
  const illustrationPath = PATHS.illustrationDetail.replace(':itemId', illustration.slug)
  
  return <NavLink to={illustrationPath} className={`illustration-card-container ${imageLoaded ? 'loaded' : ''}`}>
      {illustration.images && illustration.images[0] && <LazyImage
        onLoad={() => setImageLoaded(true)}
        src={illustration.images[0].thumbnail}
        alt={illustration.title}
        width={illustration.images[0].thumbDimensions.width}
        height={illustration.images[0].thumbDimensions.height}
      />}
      <h4 className='medium-padding-top' dangerouslySetInnerHTML={{__html: illustration.title}} />
  </NavLink>
}

export default IllustrationCard