export const toSlug = title => title.replace(/[^A-Za-z0-9\s]/g, '').replace(/\s/g, '_').toLowerCase() + '_' + (new Date()).valueOf()
export const toSlugDummy = title => title.replace(/[^A-Za-z0-9\s]/g, '').replace(/\s/g, '_').toLowerCase() + '_' + (new Date()).valueOf().toString().split('').map(n => 'X').join('')

export const altFromImageUrl = url => url.split('/').pop().split('.').shift()

export function toParamString (params = {}) {
  return Object.keys(params).map(key => {
    if (Array.isArray(params[key])) {
      return params[key].map(value => value && `${key}=${value}`).filter(v => v).join('&')
    }
    return params[key] && `${key}=${params[key]}`
  }).filter(v => v).join('&')
}

export function queryParams (locationSearch) {
  let pl = /\+/g
  let params = {}
  let paramsArray = locationSearch && locationSearch.replace(pl, ' ').substring(1).split('&')
  paramsArray && paramsArray.forEach((item) => {
    let paramName = item.split('=')[0]
    let paramValue = decodeURIComponent(item.split('=')[1])
    if (paramName) {
      if (!params[paramName]) {
        params[paramName] = paramValue
      } else {
        if (Array.isArray(params[paramName])) {
          params[paramName].push(paramValue)
        } else {
          params[paramName] = [params[paramName], paramValue]
        }
      }
    }
  })
  return params
}

export const obfuscateString = string => {
  const chars = string.split('')
  const first = chars.slice(0, parseInt(chars.length * .15)).join('')
  const last = chars.slice(-1 * parseInt(chars.length * .15)).join('')
  const mid = new Array(parseInt(chars.length * .7) + 1).fill('#').join('')
  return first + mid + last
}