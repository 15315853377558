import React, { useRef } from 'react'
import { altFromImageUrl } from '../../util/dataFormat'
import { DEFAULT_MASON } from '../../ApplicationConstants'
import Mason from 'react-stone-mason'

import './Illustration.scss'


const Illustration = ({illustration}) => {
  const [ activeImage, setActiveImage ] = React.useState(0);
  const currentImage = illustration.images && illustration.images[activeImage]
  const displayImageRef = useRef()
  const hasMultipleImages = illustration.images && illustration.images.length > 1

  const handleSelectImage = (i) => {
    setActiveImage(i)
    displayImageRef.current.scrollIntoView({behavior: 'smooth' })
  }

  return <article className='row illustration-container'>
    <div className='small-12'>
      {currentImage && <div className='text-center'><img
        ref={displayImageRef}
        src={currentImage.image}
        alt={altFromImageUrl(currentImage.image)} /></div>}
      <h2 className='x-large-padding-top' dangerouslySetInnerHTML={{__html: illustration.title}} />
      <p dangerouslySetInnerHTML={{__html: illustration.description}} />
      {hasMultipleImages && <div className='large-padding-top thumb-list'>
        <Mason columns={DEFAULT_MASON}>
        {illustration.images.map((image, index) => (
          <div key={`thumb${index}`}>
            <div className={`thumb-item ${index === activeImage ? 'selected' : ''}`}>
              <img
                onClick={() => handleSelectImage(index)} key={`i${index}`}
                src={image.thumbnail}
                alt={altFromImageUrl(image.image)} />
            </div>
          </div>
        ))}
        </Mason>
      </div>}
    </div>

  </article>
}

export default Illustration