import grid_vars from 'simpl-grid/variables.scss'

export const SECRET_KEY = 'ADMIN_SECRET'

export const GRID_VARS = grid_vars

export const DEFAULT_MASON = {
  small: {
    query: `(max-width: ${grid_vars.medium})`,
    columns: 2
  },
  medium: {
    query: `(min-width: calc(${grid_vars.medium} + 1px)) and (max-width: calc(${grid_vars.large} - 1px) )`,
    columns: 3
  },
  large: {
    query: `(min-width: ${grid_vars.large})`,
    columns: 4
  },
}

export const HOME_MASON = {
  small: {
    query: `(max-width: ${grid_vars.medium})`,
    columns: 1
  },
  medium: {
    query: `(min-width: calc(${grid_vars.medium} + 1px)) and (max-width: calc(${grid_vars.large} - 1px) )`,
    columns: 2
  },
  large: {
    query: `(min-width: ${grid_vars.large})`,
    columns: 3
  },
}