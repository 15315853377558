import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { queryParams } from '../../../../util/dataFormat'
import { getItems } from '../../../../api'
import { NavLink } from 'react-router-dom'
import { ADMIN_PATHS, NEW_ILLUSTRATION_ROUTE } from '../../Admin'

const pageSize = 40

const IllustrationAdmin = (props) => {
  const [ illustrationEntries, setIllustrationEntries ] = useState([])
  const { search } = useLocation()
  const searchParams = queryParams(search)
  const offset = +searchParams?.offset || 0
  
  useEffect(() => {
    getItems('illustration', offset, pageSize, 'date').then(res => res && setIllustrationEntries(res))
  }, [offset])
  
  return <div className='container'>
    <div className='row'>
      <div className='offset-small-0 offset-medium-2 offset-large-2 small-12 medium-10 large-8'>
        <h1 className='x-large-padding-bottom'>Illustration</h1>
        <ul>
          <li className='x-large-padding-bottom'><NavLink to={ADMIN_PATHS.illustrationDetail.replace(':itemId', NEW_ILLUSTRATION_ROUTE)}> new illustration ... </NavLink></li>
          {illustrationEntries.map((illustration, i) => {
            return <li key={`illustration-${i}`} className='medium-padding-bottom'>
              <NavLink to={ADMIN_PATHS.illustrationDetail.replace(':itemId', illustration.slug)}>{illustration.title}</NavLink>
            </li>
          })}
        </ul>
        
        <div className='row no-gutter x-large-margin-top'>
          <div className='small-6 text-left no-cell-padding'>
            {offset > pageSize - 1 && <NavLink to={`?offset=${offset - pageSize}`}>previous</NavLink>}&nbsp;
          </div>
          <div className='small-6 text-right no-cell-padding'>
            {illustrationEntries.length > 0 && <NavLink to={`?offset=${offset + pageSize}`}>next</NavLink>}
          </div>
        </div>
      
      </div>
    </div>
  
  </div>
}

export default IllustrationAdmin
