import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { getItems } from '../../../api'
import { queryParams } from '../../../util/dataFormat'
import Article from '../../../components/Article'
import { toTheTop, useDocumentTitle } from '../../../util/domUtil'

import './Blog.scss'


const pageSize = 20
const monthStyleOptions = {
    month: 'long',
}

const BlogListRoute = (props) => {
    const [blogEntries, setBlogEntries] = useState([])
    const { search } = useLocation()
    const searchParams = queryParams(search)
    const offset = +searchParams?.offset || 0
    let postsMonth = false
    let postsYear = false

    useDocumentTitle(`Greg / Dev log`)

    useEffect(() => {
        getItems('blog-entries', offset, pageSize, 'date').then(res => res && setBlogEntries(res))
    }, [offset])

    return <div className='blog-container container'>
        <div className='row'>
            <div className='offset-small-0 offset-medium-1 offset-large-2 small-12 medium-10 large-8'>
                <header className='row child-page-heading'>
                    <div className='hide-for-small medium-3' />
                    <div className='small-12 medium-9 no-padding-left'>
                        <h1>dev log</h1>
                    </div>
                </header>
                {blogEntries.map((post, i) => {

                    const postDate = new Date(post.date)
                    const showYear = postsYear !== postDate.getFullYear()
                    const showMonth = postsMonth !== postDate.getMonth()
                    const monthDisplay = new Intl.DateTimeFormat('en-US', monthStyleOptions).format(postDate)

                    postsMonth = postDate.getMonth()
                    postsYear = postDate.getFullYear()

                    return <React.Fragment key={`article-${i}`}>
                        {(showYear || showMonth) && <div className='month-row row small-gutter'>
                            <div className='small-12 medium-3 no-cell-padding medium-padding-left' >
                                <strong>{showMonth && monthDisplay} {showYear && postsYear}</strong>
                            </div>
                            <div className='hide-for-small medium-9' />
                        </div>}
                        <Article article={post} />
                    </React.Fragment>
                })}

                <div className='row no-gutter x-large-margin-top'>
                    <div className='small-6 text-left no-cell-padding'>
                        {offset > pageSize - 1 && <NavLink onClick={toTheTop} to={`?offset=${offset - pageSize}`}>previous</NavLink>}
                    </div>
                    <div className='small-6 text-right no-cell-padding'>
                        {blogEntries.length > 0 && <NavLink onClick={toTheTop} to={`?offset=${offset + pageSize}`}>next</NavLink>}
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default BlogListRoute
