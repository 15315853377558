import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import Header from './components/Header'
import { PATHS } from './routes/route-constants'
import HomeRoute from './routes/Home'
import BlogsRoute from './routes/Blog'
import IllustrationRoute from './routes/Illustration'
import ProjectsRoute from './routes/Projects'
import AdminRoute from './routes/Admin'
import PeekElement from 'react-peek-element'

import 'simpl-grid/grid.css';
import './styles/base.scss'
import './App.scss'

function App() {
  return (
    <Router>
      <div className='app-container'>
        <div className='main-content'>
          <PeekElement>
            <Header />
          </PeekElement>
          <Switch>
            <Route path={PATHS.codeProjects}><ProjectsRoute /></Route>
            <Route path={PATHS.illustration}><IllustrationRoute /></Route>
            <Route path={PATHS.blog}><BlogsRoute /></Route>
            <Route path={PATHS.admin}><AdminRoute /></Route>
            <Route path={PATHS.home}><HomeRoute /></Route>
          </Switch>
        </div>
      </div>
    </Router>
  )
}

export default App;
