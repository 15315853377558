import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Project from '../../../components/Project'
import { getItemBySlug } from '../../../api'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../route-constants'
import { useDocumentTitle } from '../../../util/domUtil'

const ProjectsDetailsRoute = (props) => {
  const [ project, setProject ] = useState( false )
  const { itemId } = useParams()

  useDocumentTitle(`Greg / Projects / ${project?.name || ''}`)

  useEffect(() => {
    itemId && getItemBySlug('code-projects', itemId).then(res => setProject(res))
  }, [itemId])

  return <div className='container xx-large-padding-top'>
    {project && <Project project={project} />}

    <NavLink to={PATHS.codeProjects}>More projects</NavLink>
  </div>
}

export default ProjectsDetailsRoute
