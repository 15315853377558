import React, { useEffect, useState } from 'react'
import { getItems } from '../../../api'
import Mason from 'react-stone-mason'
import IllustrationCard from '../../../components/Illustration/IllustrationCard'
import { GRID_VARS } from '../../../ApplicationConstants'

import './IllustrationList.scss'
import { useDocumentTitle } from '../../../util/domUtil'

const IllustrationListRoute = (props) => {
  const [illustrations, setIllustration] = useState([])
  
  useDocumentTitle(`Greg / Illustration`)
  
  const columnConfig = {
    small: {
      query: `(max-width: ${GRID_VARS.medium})`,
      columns: 2
    },
    medium: {
      query: `(min-width: calc(${GRID_VARS.medium} + 1px)) and (max-width: calc(${GRID_VARS.large} - 1px) )`,
      columns: 3
    },
    large: {
      query: `(min-width: ${GRID_VARS.large})`,
      columns: 4
    },
  }
  
  
  useEffect(() => {
    getItems('illustration', 0, 0, 'date').then(res => res && setIllustration(res))
  }, [])
  
  return <div className='illustration-list-container'>
    <div className='container'>
      <header className='row child-page-heading text-center' style={{ '--color': 'var(--red)'}}>
        <h1 className='' >Illustration</h1>
      </header>
      
      <Mason columns={columnConfig}>
        {illustrations.map((illustration, i) => <div key={`p${i}`}  className='illustration-item'>
          <IllustrationCard illustration={illustration} />
        </div>)}
      </Mason>
    </div>
  </div>
}

export default IllustrationListRoute
