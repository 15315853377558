import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Modal from 'react-modal'
import { deleteItemBySlug, getImages, getItemBySlug, updateItemBySlug } from '../../../../api'
import { toSlug, toSlugDummy } from '../../../../util/dataFormat'
import { NEW_PROJECT_ROUTE } from '../../Admin'
import SelectImagesModal from '../../../../components/Modals/SelectImages'
import Project from '../../../../components/Project'

const defaultProject = {
  name: '',
  url: '',
  description: '',
  date: '',
  images: [],
  slug: '',
}

Modal.setAppElement('#root')

const ProjectDetailsAdmin = (props) => {
  const [ project, setProject ] = useState(defaultProject)
  const [ projectImages, setProjectImages] = useState([])
  const [ modalIsOpen, setIsOpen ] = React.useState(false);
  const { itemId } = useParams()
  
  function openModal () { setIsOpen(true) }
  function closeModal () { setIsOpen(false) }
  
  useEffect(() => {
    itemId !== NEW_PROJECT_ROUTE && getItemBySlug('code-projects', itemId).then(res => setProject(res))
  }, [itemId])
  
  const handleDeleteProject = (e) => {
    const slug = project.slug
    deleteItemBySlug('code-projects', slug).then(() => alert('deleted!'))
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleUpdateProject = (e) => {
    const { _id, ...projectProperties } = project
    const slug = project.slug || toSlug(project.name)
    const date = project.date || (new Date()).toISOString()
    updateItemBySlug('code-projects', slug, { ...projectProperties, slug, date } ).then(() => alert('updated!'))
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleAddImages = e => {
    if (!projectImages.length) {
      getImages('code-projects', true).then(images => {
        setProjectImages(images)
        openModal()
      })
    } else {
      openModal()
    }
    
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleSetImageSelection = (images) => {
    setProject({...project, images})
  }
  
  const handleBumpDate = e => {
    setProject({...project, date: (new Date()).toISOString()})
    e.preventDefault()
    e.stopPropagation()
  }
  
  return <div className='container project-admin-details-container'>
    <div className='row'>
      <div className='offset-small-0 offset-medium-2 offset-large-2 small-12 medium-10 large-8'>
        <form onSubmit={handleUpdateProject}>
          <div>
            <label>
              <span>Name</span>
              <input value={project.name} onChange={e => setProject({...project, name: e.target.value})}/>
            </label>
            <label>
              <span>URL</span>
              <input value={project.url} onChange={e => setProject({...project, url: e.target.value})}/>
            </label>
            <label>
              <span>Description</span>
              <textarea value={project.description} rows='10' onChange={e => setProject({...project, description: e.target.value})}/>
            </label>
            <label>
              <span>Date</span>
              <input value={project.date} onChange={e => setProject({...project, date: e.target.value})}/>
              <button onClick={handleBumpDate}>Now</button>
            </label>
            
            <div>
              Images<br/>
              <div className='medium-padding-top medium-padding-bottom'>
                {(!project.images || project.images.length === 0) && <em>none</em>}
                {(project.images || []).map((image, i) => <div key={`image-${i}`}>{image.image}</div>)}
              </div>
              <button className='secondary' onClick={handleAddImages}>select image</button>
            </div>
            <p>slug: { project.slug || toSlugDummy(project.name) } </p>
            <div className='row no-gutter xx-large-padding-top'>
              <div className='small-6 text-left no-cell-padding'>
                {itemId !== NEW_PROJECT_ROUTE && <button className='secondary red' onClick={handleDeleteProject}>Delete</button>}
              </div>
              <div className='small-6 text-right no-cell-padding'>
                <button>{itemId === NEW_PROJECT_ROUTE ? 'Save' : 'Update'}</button>&nbsp;
              </div>
            </div>
          </div>
        </form>
        <hr/>
        <strong>preview</strong>
        <Project project={{...project, date: (new Date()).toISOString() }} />
      </div>
    </div>
    
    <SelectImagesModal
      selectedImages={project.images || []}
      imageOptions={projectImages}
      setSelectedImages={handleSetImageSelection}
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    />
  
  
  </div>
}

export default ProjectDetailsAdmin