import React from 'react'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../routes/route-constants'

import './Header.scss'

const Header = (props) => {
    return <header className='site-header'>
        <nav>
            <NavLink to={PATHS.home} className='main-link' ><strong>Greg Archer</strong></NavLink>
            <br className='show-for-small'/>
            
            <NavLink to={PATHS.codeProjects} className='page-link' >Projects</NavLink>
            <NavLink to={PATHS.illustration} className='page-link' >Illustration</NavLink>
            <NavLink to={PATHS.blog} className='page-link' >Blog</NavLink>
        </nav>
    </header>
}

export default Header
