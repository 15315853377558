import { useRef, useEffect } from 'react'

export const toTheTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

export function useDocumentTitle(title) {
  const defaultTitle = useRef(document.title)
  
  useEffect(() => {
    const oldTitle = defaultTitle.current
    document.title = title
    
    return () => { document.title = oldTitle }
  }, [title])
}
