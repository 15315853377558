import React from 'react'
import Modal from 'react-modal'
import Mason from 'react-stone-mason'
import { DEFAULT_MASON } from '../../ApplicationConstants'

import './SelectImages.scss'

const SelectImagesModal = (props) => {
  const {
    selectedImages = [],
    imageOptions = [],
    setSelectedImages,
    modalIsOpen,
    closeModal
  } = props
  
  
  return <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    contentLabel="Select Images"
  >
    <h2>Select Images</h2>
    <button className='modal-close' onClick={closeModal}>close</button>
    <div className='select-image-list thumb-list'>
      <Mason columns={DEFAULT_MASON}>
      {imageOptions.map((image, i) => {
        const selected = selectedImages.find(articleImage => articleImage.image === image.image)
        const imagesWith = [...selectedImages, image]
        const imagesWithout = [...selectedImages]
        imagesWithout.splice(selectedImages.indexOf(selected), 1)
        
        const selectImage = () => {setSelectedImages(imagesWith)}
        const deselectImage = () => {setSelectedImages(imagesWithout)}
        return <div
          key={`new-image-${i}`}
          onClick={selected ? deselectImage : selectImage }>
          <div className={`thumb-item ${selected ? 'selected' : ''}`}>
            <img src={`${image.thumbnail}`} alt={image.image}/>
          </div>
        </div>})}
      </Mason>
    </div>
  </Modal>
}
export default SelectImagesModal